import { ReloadEventData } from 'features/analytics/redux/analytics-v2.actions';
import { Subject } from 'rxjs/Subject';
import { AvailableLoadSummary } from 'shared/models/loads/load-summaries/available-load-summary.model';

export const RELOAD_DETAILS_MODAL = 'reload-details';

export interface ReloadDetailsContext {
  visibilityChanges: Subject<boolean>;
  load: AvailableLoadSummary;
  reloadData?: ReloadEventData;
}
