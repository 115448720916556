import { Subject } from 'rxjs/Subject';

import { AvailableLoadSummary } from 'shared/models/loads/load-summaries/available-load-summary.model';

export const LOAD_DETAILS_MODAL = 'load-details';

export interface LoadDetailsContext {
  visibilityChanges: Subject<boolean>;
  load: AvailableLoadSummary;
}
